<form [formGroup]="form" (ngSubmit)="save()" class="plant" xmlns="http://www.w3.org/1999/html">
  <app-header>
    <jbm-view-title [caption]="('company.plant'|translate)+' '+('ui.edit'|translate|lowercase)"
      [parentLinkCaption]="'company.and-plants'|translate"
      [parentLinkSlug]="'settings/company'">
    </jbm-view-title>
    <div class="toolbar float-end">
      <jbm-save-button [subject]="'company.plant'|translate|titlecase" [small]="false" (click)="save()"></jbm-save-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cancel-button context="light" [small]="false" (click)="cancel()"></jbm-cancel-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
      <jbm-activity-log-button [title]="'company.plant'|translate"
       [subject_type]="24" [subject_id]="plant.id"></jbm-activity-log-button>
    </div>
  </app-header>
  <section class="module padding padding-top overflow-y bg-white">
    <jbm-static-label [value]="formControls.code.value" labelClass="width-8" labelCaption="{{'entity.code'|translate}}"></jbm-static-label>
    <jbm-text-input [formControl]="formControls.name" [submitted]="isSubmitted" [id]="'name'"
      required="true" labelCaption="{{'entity.name'|translate}}" labelClass="width-8" class="input-20">
      <jbm-input-error [formControl]="formControls.name" [submitted]="isSubmitted">
        {{'entity.requiredfield'|translate}}
      </jbm-input-error>
    </jbm-text-input>
    <fieldset class="mt-3 width-36"><legend>{{'entity.address'|translate}}</legend>
      <jbm-text-input [formControl]="formControls.postcode" [submitted]="isSubmitted" [id]="'postalcode'" class="input-7"
        required="required" [maxlength]="7" (onBlur)="updateAddress()"
        labelCaption="{{'entity.postalcode'|translate}}" labelClass="width-7">
        <jbm-input-error [formControl]="formControls.postcode" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.no" [submitted]="isSubmitted" [id]="'housenr'"  class="input-7"
        required="required" [maxlength]="15" labelCaption="{{'entity.housenumber'|translate}}" labelClass="width-7"
        (onBlur)="updateAddress()">
        <jbm-input-error [formControl]="formControls.no" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.street" [submitted]="isSubmitted" [id]="'street'"  class="input-23"
        required="required" [maxlength]="75" labelCaption="{{'entity.streetname'|translate}}" labelClass="width-7"
        (onBlur)="updateAddress()">
        <jbm-input-error [formControl]="formControls.street" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.city" [submitted]="isSubmitted" [id]="'city'"  class="input-23"
        required="required" [maxlength]="50" labelCaption="{{'entity.cityname'|translate}}" labelClass="width-7"
        (onBlur)="updateAddress()">
        <jbm-input-error [formControl]="formControls.city" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-text-input>
      <fieldset style="position: relative"><legend>{{'gps.location'|translate}}</legend>
        <ngb-alert *ngIf="error_lonlat" [type]="'warning'" [dismissible]="false">{{error_lonlat}}</ngb-alert>
        <jbm-text-input [formControl]="formControls.lon" [submitted]="isSubmitted" [id]="'longitude'"  class="input-8"
          [maxlength]="13" labelCaption="{{'gps.longitude'|translate}}" labelClass="width-6">
          <jbm-input-error [formControl]="formControls.lon" [submitted]="isSubmitted">
            {{'gps.longitude-latitude-format'|translate}}
          </jbm-input-error>
        </jbm-text-input>
        <jbm-text-input [formControl]="formControls.lat" [submitted]="isSubmitted" [id]="'latitude'"  class="input-8"
          [maxlength]="12" labelCaption="{{'gps.latitude'|translate}}" labelClass="width-6">
          <jbm-input-error [formControl]="formControls.lat" [submitted]="isSubmitted">
            {{'gps.longitude-latitude-format'|translate}}
          </jbm-input-error>
        </jbm-text-input>
        <div *ngIf="GPS_address.latitude && formControls.lat.value && formControls.lon.value">
          <div class="width-6 float-start">&nbsp;</div>
          <div class="float-start ml-2">
            <jbm-gps-location-button
              [latitude]="GPS_address.latitude"
              [longitude]="GPS_address.longitude"
              title="{{'customer'|translate}}&nbsp;{{'entity.address'|translate|lowercase}}"
              [address]="formControls.street.value+'&nbsp;'+formControls.no.value+', '+formControls.city.value"
            ></jbm-gps-location-button>
          </div>
        </div>
      </fieldset>
    </fieldset>
    <jbm-float-input-group *ngIf="financialRights.AllowRead" [formControl]="formControls.baseprice" [id]="'baseprice'"
      [editable]="financialRights.AllowUpdate" labelCaption="{{'concrete.price'|translate}}"
      labelClass="width-8" class="input-8" [prepend]="'&euro;'">
      <jbm-input-error [formControl]="formControls.baseprice" [submitted]="isSubmitted">
         <span *ngIf="formControls.baseprice.hasError('priceInvalid')">
              {{'entity.priceformat'|translate}}</span>
      </jbm-input-error>
    </jbm-float-input-group>

    <fieldset class="mt-3 width-36"><legend>{{'order.production'|translate}}</legend>
      <jbm-integer-input-group [formControl]="formControls.production_capacity" [id]="'production_capacity'"
        [editable]="financialRights.AllowUpdate" labelCaption="{{'production.capacity'|translate}}"
        labelClass="width-7" class="xxxs" [maxlength]="4" [append]="('quantity.m3'|translate)+'/'+('datetime.hour'|translate)">
        <jbm-input-error [formControl]="formControls.production_capacity" [submitted]="isSubmitted">
          <span *ngIf="formControls.production_capacity.hasError('pattern')">{{'entity.integer-format'|translate}}</span>
        </jbm-input-error>
      </jbm-integer-input-group>
      <jbm-integer-input-group [formControl]="formControls.driving_time_margin" [id]="'driving_time_margin'"
        [editable]="financialRights.AllowUpdate" labelCaption="{{'production.driving-time-margin'|translate}}"
        labelClass="width-7" class="xxxs" [maxlength]="3" [append]="'datetime.minutes'|translate">
        <jbm-help-text class="width-9" [helptext]="'production.driving-time-margin-info'|translate"></jbm-help-text>
        <jbm-input-error [formControl]="formControls.driving_time_margin" [submitted]="isSubmitted">
          <span *ngIf="formControls.driving_time_margin.hasError('pattern')">{{'entity.integer-format'|translate}}</span>
        </jbm-input-error>
      </jbm-integer-input-group>
    </fieldset>
  </section>
</form>
<jbm-toasts></jbm-toasts>
