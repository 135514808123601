<app-header>
   <jbm-view-title [caption]="'menu.invoicing'|translate" [iconClassname]="'calculator'"
     subCaption="{{'invoice.invoiceable'|translate}} {{'menu.production'|translate|lowercase}}{{'production.receipts'|translate|lowercase}}"
     [parentLinkCaption]="'menu.invoicing'|translate" [parentLinkSlug]="'invoicing'" [itemcount]="itemCount">
   </jbm-view-title>
   <div class="toolbar ml-4 float-start">
      <label *ngIf="plantOptions.length">{{'company.plant'|translate}}</label>
      <select *ngIf="plantOptions.length" (change)="changePlant($event)" class="form-control form-control-sm form-select">
         <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
      </select>
   </div>
   <div class="toolbar float-end">
      <jbm-spinner [loading]="loading"></jbm-spinner>
      <div class="toolbar-spacer-1"></div>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module padding invoiceable-receipts">
   <div *ngFor="let invoiceable_project of projects" class="mt-3">
      <div class="bg-white p-2 clearfix project">
         <table class="table table-xs table-borderless float-start d-inline-block">
            <tr>
               <td>{{'entity.project'|translate}}:</td>
               <td class="column-1">
                  <jbm-link-button
                    [caption]="invoiceable_project.project.name"
                    [style]="'margin-top:-0.15rem'" (onClick)="showProject(invoiceable_project.project.id)">
                  </jbm-link-button>
               </td>
               <td class="column-2"><span *ngIf="invoiceable_project.project.description" class="p-0">{{'entity.description'|translate}}:</span></td>
               <td class="column-3 fw-bold">
                  <span *ngIf="invoiceable_project.project.description" class="fw-bold mr-3">{{invoiceable_project.project.description}}</span>
               </td>
               <td>{{'project.state'|translate}}:</td>
               <td class="fw-bold">{{invoiceable_project.project.projectState.description}}</td>
            </tr>
            <tr>
               <td>{{'customer'|translate}}:</td>
               <td class="column-1">
                  <jbm-link-button
                    [caption]="invoiceable_project.customer.name"
                    [style]="'margin-top:-0.15rem'" (onClick)="showCustomer(invoiceable_project.customer.id)">
                  </jbm-link-button>
               </td>
               <td *ngIf="invoiceable_project.project.address">{{'project.work-location'|translate}}:</td>
               <td *ngIf="invoiceable_project.project.address" colspan="3" class="fw-bold">
                  <span class="mr-1">
                     {{invoiceable_project.project.address.street}}&nbsp;{{invoiceable_project.project.address.no}},&nbsp;{{invoiceable_project.project.address.city}}
                  </span>
                  <jbm-gps-location-button btnContext="link"
                    [address]="invoiceable_project.project.address.street+'&nbsp;'+invoiceable_project.project.address.no+', '+invoiceable_project.project.address.city"
                    [latitude]="invoiceable_project.project.address.lat" [longitude]="invoiceable_project.project.address.lon"
                    [ngbTooltip]="('project.work-location'|translate)+' '+('geo.map'|translate|lowercase)"
                    [className]="'p-0'"
                    [extraSmall]="false">
                  </jbm-gps-location-button>
               </td>
            </tr>
         </table>
         <div class="float-end">
            <table class="table table-xs table-borderless">
               <tr><td class="align-right fw-bold">{{invoiceable_project.project.plant.name}}</td></tr>
               <tr><td class="align-right">
                  <button type="button" class="btn btn-outline-secondary btn-sm"
                    [ngClass]="{active: expanded==invoiceable_project.project.id}"
                    (click)="toggleReceipts(invoiceable_project.project.id)">
                     <span *ngIf="expanded!=invoiceable_project.project.id"><i class="far fa-square"></i>&nbsp;</span>
                     <span *ngIf="expanded==invoiceable_project.project.id"><i class="far fa-check-square"></i>&nbsp;</span>
                     {{'menu.production'|translate}} {{'production.receipts'|translate|lowercase}}&nbsp;
                     <span class="badge bg-light text-dark">{{invoiceable_project.receipts_count}}</span>
                  </button>
               </td></tr>
            </table>
         </div>
      </div>

      <div class="bg-blue-extra-light p-1 pl-2 clearfix">
         <div class="float-start">
            <label class="col-form-label float-start mr-2">{{'invoice.date'|translate}}:</label>
            <div class="float-start">
               <input class="form-control form-control-sm delivery-date" [maxLength]="10" [placeholder]="'datetime.date-format'|translate" [placement]="'right'"
                 [container]="'body'" name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker" (change)="onChangeDate()"
                 (blur)="onChangeDate()" [ngClass]="{'ng-valid':validateDate(), 'is-invalid':this.dateTouched && !validateDate()}">
            </div>
            <div class="float-start">
               <button type="button" class="btn btn-sm btn-light" (click)="d.toggle()">
                  <i class="fa fa-calendar"></i>
               </button>
            </div>
         </div>
         <button type="button" class="btn btn-sm btn-primary float-end"
           (click)="generateInvoice(invoiceable_project.project.id)">{{'entity.invoice'|translate}} {{'ui.generate'|translate|lowercase}}
         </button>
      </div>

      <div *ngIf="expanded==invoiceable_project.project.id" class="table-responsive bg-white">
         <table class="table table-xs table-hover table-row-selectable receipts">
            <thead>
               <tr>
                  <th *ngIf="receipts.length>1" class="check"><input type="checkbox" (change)="toggleProject()"
                    [checked]="receipt_ids.length==receipts.length"></th>
                  <th class="id">{{'ui.number'|translate}}</th>
                  <th class="date">{{'order.production'|translate}}</th>
                  <th class="recipe">{{'entity.recipe'|translate}}</th>
                  <th class="amount align-right">{{'order.delivered'|translate}}</th>
                  <th class="worktype">{{'order.worktype'|translate}}</th>
                  <th class="description">{{'entity.description'|translate}}</th>
                  <th class="comments">{{'project.order'|translate}}</th>
                  <th class="comments">{{'production.receipt'|translate}}</th>
                  <th class="actions"></th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let receipt of receipts" (click)="toggleReceipt(receipt.id)">
                  <td *ngIf="receipts.length>1">
                     <input type="checkbox" (click)="toggleReceipt(receipt.id); $event.stopPropagation()"
                    [checked]="receipt_ids.indexOf(receipt.id)>-1">
                  </td>
                  <td>{{receipt.id}}</td>
                  <td *ngIf="receipt.order.plants_id==null">{{receipt.execute_date}}</td>
                  <td *ngIf="receipt.order.plants_id!=null">
                     {{receipt.execute_date}}<br />&nbsp;<i class="text-muted fa fa-industry"></i> {{receipt.plant.code}}
                  </td>
                  <td>
                     <jbm-link-button [caption]="receipt.recipe.code"
                       (onClick)="showRecipe(receipt.recipe.id, receipt.execute_date,receipt.order.concrete_specs.environment ? receipt.order.concrete_specs.environment.description : '')">
                     </jbm-link-button>
                  </td>
                  <td class="amount align-right">{{receipt.receipt_amount}}</td>
                  <td>
                     <jbm-link-button [caption]="receipt.order.worktype.description" (onClick)="showOrder(receipt.orders_id)"></jbm-link-button>
                  </td>
                  <td>
                     <jbm-link-button *ngIf="receipt.order.description" [caption]="receipt.order.description" (onClick)="showOrder(receipt.orders_id)"></jbm-link-button>
                  </td>
                  <td><jbm-comments-dropdown [comments]="receipt.comments"></jbm-comments-dropdown></td>
                  <td><jbm-comments-dropdown [comments]="receipt.receipt_comments"></jbm-comments-dropdown></td>
                  <td>
                     <button *ngIf="receipts.length>1" type="button" class="btn btn-sm btn-primary float-end"
                       (click)="generateInvoice(invoiceable_project.project.id, receipt.id); $event.stopPropagation()">{{'entity.invoice'|translate}} {{'ui.generate'|translate|lowercase}}
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
      <div *ngIf="expanded==invoiceable_project.project.id && receipt_ids.length>1" class="pl-1">
         <i class="fas fa-check-square mr-4"></i><i class="fa fa-long-arrow-alt-right mr-4"></i>
         <button type="button" class="btn btn-sm btn-primary"
           (click)="generateReceiptsInvoice(invoiceable_project.project.id)">{{'entity.invoices'|translate}} {{'ui.generate'|translate|lowercase}}
         </button>
      </div>
   </div>
   <jbm-empty-view [emptyView]="!loading && itemCount==0"></jbm-empty-view>
</section>
<jbm-toasts></jbm-toasts>

<jbm-modal [id]="'projectDetails'" [className]="'project-details'"></jbm-modal>
<ng-template #modalProjectDetails let-modal>
   <project-details [project_id]="project_id" (onClose)="closeProject()"></project-details>
</ng-template>

<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
   <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>

<jbm-modal [id]="'recipe'" [className]="'invoice-recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
   <recipe-details [delivery_date]="delivery_date" [recipe_id]="recipe_id" [environment]="environment" (onClose)="closeRecipe()"></recipe-details>
</ng-template>

<jbm-modal [id]="'order'" [className]="'order-details'"></jbm-modal>
<ng-template #modalOrder let-modal>
   <order-details [order_id]="order_id" (onClose)="closeOrder()"></order-details>
</ng-template>
