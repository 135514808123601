import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../../shared/shared.module";
import { JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import { JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import { JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import { JBMFormModule } from "../../components/JBM/Forms/jbmform.module";
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMDropdownsModule} from "../../components/JBM/Dropdowns/jbmdropdowns.module";
import { JBMMediaModule} from "../../components/JBM/Media/jbmmedia.module";
import { JBMPopoversModule} from "../../components/JBM/Popovers/jbmpopovers.module";
import { SharedCustomersModule} from "../../shared/customers/shared.customers.module";
import { SharedProjectsModule} from "../../shared/projects/shared.projects.module";
import { PresentationsModule} from "../presentations/presentations.module";
import { SelectorsModule} from "../../components/domain/selectors/selectors.module";
import { InvoicingRoutingModule } from './invoicing-routing.module';
import { InvoicesComponent} from "./datatable/invoices.component";
import { InvoiceStatesFlowComponent } from './states-flow/invoice-states-flow.component';
import { InvoiceViewComponent } from './invoice-view/invoice-view.component';
import { InvoiceDetailsComponent } from './details/invoice-details.component';
import { InvoiceItemsComponent } from './items/invoice-items.component';
import { InvoicePreviewComponent } from './preview/invoice-preview.component';
import { InvoiceableReceiptsComponent} from "./invoiceable-receipts/invoiceable-receipts.component";
import { InvoiceValidationComponent} from "./state-actions/validation/invoice-validation.component";
import { InvoiceApproveComponent } from './state-actions/approve/invoice-approve.component';
import { InvoiceCopyComponent} from "./state-actions/copy/invoice-copy.component";
import { InvoiceMakeConceptComponent} from "./state-actions/make-concept/invoice-make-concept.component";
import { InvoiceHandlePaymentComponent } from './state-actions/handle-payment/invoice-handle-payment.component';
import { InvoiceSendComponent} from "./state-actions/send/invoice-send.component";
import { InvoiceCreditnotaComponent} from './creditnota/invoice-creditnota.component';
import { InvoiceRejectComponent } from './state-actions/reject/invoice-reject.component';
import { InvoiceRemoveComponent} from "./state-actions/remove/invoice-remove.component";
import { InvoiceArticlesComponent } from './articles/invoice-articles.component';
import { InvoiceResourcesComponent} from "./resources/invoice-resources.component";
import { InvoiceMandatoryArticlesComponent} from "./mandatory-articles/invoice-mandatory-articles.component";
import { InvoiceReceiptsComponent } from './receipts/invoice-receipts.component';
import { InvoiceConcretesComponent } from './concretes/invoice-concretes.component';
import { InvoiceRecipesComponent } from './recipes/invoice-recipes.component';
import { CustomerCommentsComponent } from './details/customer-comments/customer-comments.component';
import { SharedRecipesModule} from "../../shared/recipes/shared.recipes.module";
import { InvoicesHistoryComponent } from './history/invoices-history.component';
import { CustomerInvoiceFormComponent } from './customer-form/customer-invoice-form.component';
import { ProjectInvoiceFormComponent} from "./project-form/project-invoice-form.component";
import { InvoiceSettingsComponent} from "./settings/invoice-settings.component";
import { InvoiceViewSettingsComponent } from './invoice-view/settings/invoice-view-settings.component';
import { InvoicesValidationComponent } from './invoices-validation/invoices-validation.component';
import { InvoiceValidationFormComponent } from './invoices-validation/invoice-validation-form/invoice-validation-form.component';
import { InvoiceValidationsComponent } from './invoices-validation/invoice-validations/invoice-validations.component';
import { InvoiceOrdersComponent } from './orders/invoice-orders.component';
import {LayoutModule} from '../../layout/layout.module';
import {ConcreteCommentsComponent} from './concretes/comments/concrete-comments.component';

@NgModule({
  declarations: [
  	InvoicesComponent,
	InvoiceStatesFlowComponent,
	InvoiceViewComponent,
    InvoiceDetailsComponent,
	InvoiceItemsComponent,
	InvoicePreviewComponent,
	InvoiceableReceiptsComponent,
	InvoiceValidationComponent,
	InvoiceApproveComponent,
    InvoiceCopyComponent,
	InvoiceMakeConceptComponent,
	InvoiceHandlePaymentComponent,
	InvoiceSendComponent,
    InvoiceCreditnotaComponent,
	InvoiceSettingsComponent,
	InvoiceRejectComponent,
	InvoiceRemoveComponent,
	InvoiceSettingsComponent,
	InvoiceViewSettingsComponent,
    InvoiceArticlesComponent,
	InvoiceResourcesComponent,
	InvoiceMandatoryArticlesComponent,
	InvoiceReceiptsComponent,
	InvoiceConcretesComponent,
	ConcreteCommentsComponent,
	InvoiceRecipesComponent,
	CustomerCommentsComponent,
	InvoicesHistoryComponent,
	CustomerInvoiceFormComponent,
    ProjectInvoiceFormComponent,
	InvoicesValidationComponent,
	InvoiceValidationFormComponent,
	InvoiceValidationsComponent,
	InvoiceOrdersComponent
    ],
    imports: [CommonModule, InvoicingRoutingModule, SharedModule, SelectorsModule, JBMViewModule, JBMModalModule, JBMFormModule,
        JBMTableModule, JBMButtonsModule, JBMDropdownsModule, SharedCustomersModule, SharedProjectsModule, PresentationsModule, JBMMediaModule, JBMPopoversModule, SharedRecipesModule, LayoutModule]
})
export class InvoicingModule { }
