import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {PlantsService} from "../../company/data/plants.service";
import {InvoicesService} from "../data/invoices.service";
import {InvoiceStatesService} from "../data/invoice-states.service";
import {CustomerService} from "../../customers/data/customer.service";
import {InvoiceAttachmentsService} from "../data/invoice-attachments.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {PriceService} from "../../../services/price/price.service";
import {SanitizationService} from "../../../services/sanitization/sanitization.service";
import {StateService} from "../../../services/storage/state.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType,
  JBMTableComponent
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {TablelayoutService} from "../../users/data/tablelayout.service";
import {Subscription} from "rxjs";
import {InvoiceState} from "../data/interfaces/invoice-state";
import {Invoice} from "../data/interfaces/invoice";
import {JBMSelectOption} from '../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component';
import {GroupRights} from '../../users/data/interfaces/grouprights';

const tableName='invoices';

@Component({
  selector: 'invoices-table',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit, OnDestroy {
  baseURL: string='';
  items: any[]=[];
  invoice: Invoice;
  invoiceStates: any[]=[];
  invoiceState: InvoiceState={ id: 0, description: '', color: '', text_color: '' };
  invoiceStateID: number=0;

  plantOptions: JBMSelectOption[]=[];
  plant_id: number=0;

  customer: any;

  initialCount: number=null;
  userRights: GroupRights;
  columnDefs: JBMTableColumnDef[];
  language: string=this.TokenService.getLanguage();
  id: number;
  invoiceNumber: string;

  historyView: boolean=false;
  historyViewCustomer: boolean=false;

  invoices$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';
  filtersVisible: boolean=false;
  loading: boolean=false;

  @ViewChild('table') table: JBMTableComponent;

  @ViewChild('templateValidation') templateValidation: TemplateRef<any>;
  @ViewChild('templateCustomer') templateCustomer: TemplateRef<any>;
  @ViewChild('templateProject') templateProject: TemplateRef<any>;
  @ViewChild('templateInvoiceState') templateInvoiceState: TemplateRef<any>;
  @ViewChild('templateTotal') templateTotal: TemplateRef<any>;
  @ViewChild('templatePreview') templatePreview: TemplateRef<any>;
  @ViewChild('modalStatesFlow') modalStatesFlow: ElementRef;
  @ViewChild('modalStateComments') modalStateComments: ElementRef;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
  @ViewChild('modalInvoice') modalInvoice: ElementRef;

  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;
  @ViewChild('modalProjectDetails') modalProjectDetails: ElementRef;

  @ViewChild('modalValidate') modalValidate: ElementRef;
  @ViewChild('modalApprove') modalApprove: ElementRef;
  @ViewChild('modalCopy') modalCopy: ElementRef;
  @ViewChild('modalMakeConcept') modalMakeConcept: ElementRef;
  @ViewChild('modalSend') modalSend: ElementRef;
  @ViewChild('modalHandlePayment') modalHandlePayment: ElementRef;
  @ViewChild('modalReject') modalReject: ElementRef;
  @ViewChild('modalCreditNota') modalCreditNota: ElementRef;
  @ViewChild('modalRemove') modalRemove: ElementRef;
  @ViewChild('modalSettings') modalSettings: ElementRef;
  @ViewChild('modalPreview') modalPreview: ElementRef;

  constructor(
      private PlantsService: PlantsService,
      private InvoicesService: InvoicesService,
      private InvoiceStatesService: InvoiceStatesService,
      private CustomerService: CustomerService,
      private InvoiceAttachmentsService: InvoiceAttachmentsService,
      private TokenService: TokenService,
      private PriceService: PriceService,
      private SanitizationService: SanitizationService,
      private StateService: StateService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService,
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      private TranslateService: TranslateService,
      private TablelayoutService: TablelayoutService,
      private Title: Title
  ) {}

  ngOnInit(): void {
    // Get base URL
    const parsedUrl = new URL(window.location.href);
    this.baseURL = parsedUrl.origin;

    // Refresh data when modal outlet closes
    this.Router.events.subscribe((event: Event) => {
      if(event instanceof NavigationEnd && event.url==='/invoicing')
        this.getData();
    });

    this.userRights = this.TokenService.getRightsByName('invoicing');

    this.ActivatedRoute.data.subscribe(data => {
      this.historyView=data.hasOwnProperty('history');
      this.historyViewCustomer=data.hasOwnProperty('customer');
    });

    // Set window title if in history view
    if(this.historyView)
      this.Title.setTitle(this.TranslateService.GetTranslation('entity.invoice')+this.TranslateService.GetTranslation('entity.history').toLowerCase());

    if(this.historyView && this.historyViewCustomer) {
      // The route resolver has fetched the customer data
      this.customer=this.ActivatedRoute.snapshot.data.customer.data;
    }

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.addAliasedColumn('invoice_date','i.invoice_date');
    this.queryHttpHelper.addAliasedColumn('due_date','i.due_date');
    this.queryHttpHelper.addAliasedColumn('invoice_number','i.invoice_number');
    this.queryHttpHelper.addAliasedColumn('customer','c.name');
    this.queryHttpHelper.addAliasedColumn('project','p.name');
    this.queryHttpHelper.setSortColumn('due_date');
    this.queryHttpHelper.addSearchColumn('invoice_number', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('customer', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('project', SearchColumnType.string);

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id, tag: 'id' },
      { name: 'invoice_number', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('invoice.number-short'), visible: true,
        hideable: false, sortable: true, filterable: true, width: 7 },
      { name: 'customer', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('entity.customer'), visible: true,
        hideable: false, sortable: true, filterable: true },
      { name: 'project', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('entity.project'), visible: true,
        hideable: true, sortable: true, filterable: true, width: 20 },
      { name: 'validation', header: this.TranslateService.GetTranslation('ui.validation'), visible: false, width: 10.5,
        hideable: false, sortable: false, filterable: false },
      { name: 'invoice_states_id', header: this.TranslateService.GetTranslation('invoice.state'), visible: true, width: 7.5,
        hideable: false, sortable: true, filterable: false, className: 'opacity-7' },
      { name: 'invoice_date', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('invoice.date'),
        hideable: true, sortable: true, width: 7 },
      { name: 'due_date', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('entity.due-date'),
        hideable: true, sortable: true, width: 7 },
      { name: 'totalprice', type: JBMTableColumnType.number, visible: true, header: this.TranslateService.GetTranslation('ui.totalprice'),
        hideable: true, sortable: true, filterable: false, width: 7.5 },
      { name: 'preview', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('ui.preview'),
        hideable: false, sortable: false, filterable: false, width: 4 },
    ];
    if(!this.historyView)
      // Only actions in default invoices view
      this.columnDefs.push(
          { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, hideable: false, width: 3.5 }
      );

      this.TablelayoutService.setLayout(tableName, this.columnDefs);

    this.invoice=this.InvoicesService.getEmpty();

    // Set invoice history parameter
    let params=new HttpParams();
    if(this.historyView)
      params=params.append('history','1');

    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=>{
          if(plants.length>1) {
            this.plantOptions = this.PlantsService.getSelectOptions(plants);
            this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
          }

          this.InvoiceStatesService.get(this.language, params).subscribe(
              (data)=>this.invoiceStates=data,
              (error)=>console.error(error),
              ()=> {
                this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
                this.getData(true);
              }
          )
        }
    );
  }
  getData(countRows:boolean=false) {

    this.loading=true;

    this.columnDefs[5].template=this.templateInvoiceState;
    this.columnDefs[2].template=this.templateCustomer;
    this.columnDefs[3].template=this.templateProject;
    this.columnDefs[4].template=this.templateValidation;
    this.columnDefs[8].template=this.templateTotal;
    this.columnDefs[9].template=this.templatePreview;
    if(!this.historyView)
      this.columnDefs[10].template=this.actionsTemplate;

    if(this.historyView && this.historyViewCustomer) {
      this.queryHttpHelper.setFilterItem('customer', this.customer.name);
      this.filtersVisible=true;
      setTimeout(()=> {
        (<HTMLInputElement>document.getElementById('columnfilter-customer')).value=this.customer.name;
      });
    }

    let params=this.queryHttpHelper.getHttpParams(false);
    // Include invoice state description
    params=params.append('state','1');
    params=params.append('validation_status','1');
    params=params.append('permissions','1');
    params=this.setFilters(params);

    this.invoices$=this.InvoicesService.get(this.language, params).subscribe(
        (data: any)=>this.items=data.data,
        (error)=>console.error(error),
        ()=>{
          this.loading=false;
          this.formatData();
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            params=this.setFilters(params);
            this.count$=this.InvoicesService.get(this.language, params).subscribe(
                (data: any) => {
                  this.queryHttpHelper.paginationProps.itemCount=data.data;
                  if(this.initialCount===null) this.initialCount=data.data
                },
                (error)=> console.error(error),()=>{} );
          }
        } );
  }
  setFilters(params: HttpParams): HttpParams {
    if(this.plant_id>0)
        // Filter plant
      params=params.append('plant_id',this.plant_id.toString());

    if(this.invoiceStateID>0) {
      this.invoiceState=this.InvoiceStatesService.getByID(this.invoiceStates, this.invoiceStateID);
      // Filter invoice state
      params=params.append('invoice_states_id', this.invoiceStateID.toString());
    }

    // Set invoice history parameter
    if(this.historyView)
      params=params.append('history','1');

    return params;
  }
  formatData() {
    for(let item of this.items) {
      // Set invoice state context colors
      item.color='';
      item.text_color='';
      let invoiceState=this.InvoiceStatesService.getByID(this.invoiceStates, item.invoice_states_id);
      if(invoiceState) {
        item.color=invoiceState.color;
        item.text_color=invoiceState.text_color;
      }
    }
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    setTimeout(()=>this.getData(),1000);
  }
  changePlant(event) {
    this.plant_id=parseInt(event.target.value);

    this.queryHttpHelper.clearFilters();
    for(let c=0; c<this.table.columnDefs.length; c++)
      this.table.columnDefs[c].filterValue='';
    this.queryHttpHelper.setSearchValue('');

    this.getData(true);
  }
  setInvoiceState(id: number) {
    this.invoiceStateID=id;
    this.columnDefs[4].visible=(this.invoiceStateID===150);
    this.columnDefs[5].visible=(this.invoiceStateID===0);
    this.getData(true);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  saveLayout() {
    this.TablelayoutService.saveLayout(tableName, this.columnDefs);
  }

  showInvoiceableReceipts() {
    this.Router.navigateByUrl('invoicing/invoiceable-receipts');
  }

  // Item actions
  rowSelect(row) {
    if(!this.historyView)
      this.Router.navigateByUrl('invoicing/'+row.id);
    else
      this.Router.navigateByUrl('invoicing-history/'+row.id);
  }

  // Customer
  showCustomer(customer_id: number) {
    this.CustomerService.get(customer_id,true).subscribe(
      (data)=>this.customer=data.data,
      (error)=>console.error(error),
      ()=>this.ModalService.open(this.modalCustomerDetails,'customerDetails')
    );
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }

  // Project
  showProject(project_id: number) {
    this.id=project_id;
    this.ModalService.open(this.modalProjectDetails,'projectDetails')
  }
  closeProject() {
    this.ModalService.close('projectDetails');
  }

  // Create invoice
  createCustomer() {
    this.Router.navigate([ { outlets: {'modal': ['invoicing', 'customer-create']}} ] );
  }
  createProject() {
    this.Router.navigate([ { outlets: {'modal': ['invoicing', 'project-create']}} ] );
  }

  // Validation
  validateInvoices() {
    this.Router.navigate([ { outlets: {'modal': ['invoicing', 'validation']}} ] );
  }
  validateInvoice(invoice_id: number) {
    this.Router.navigate([ { outlets: {'modal': ['invoicing', 'validation', invoice_id.toString()]}} ] );
  }

  // Invoice states flow
  showStateFlow(id: number, invoiceNumber: string) {
    this.id=id;
    this.invoiceNumber=invoiceNumber;
    this.ModalService.open(this.modalStatesFlow,'statesFlow');
  }
  statesFlowClose() {
    this.ModalService.close('statesFlow');
  }

  // Invoice state actions
  validate(data) {
    this.invoice=data;
    this.ModalService.open(this.modalValidate,'stateAction');
  }
  validateSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    this.actionSuccess();
  }
  approve(data) {
    this.invoice=data;
    this.ModalService.open(this.modalApprove,'stateAction');
  }
  approveSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.approved'));
    this.actionSuccess();
  }
  makeConcept(data) {
    this.invoice=data;
    this.ModalService.open(this.modalMakeConcept,'stateAction');
  }
  conceptSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.made-concept'));
    this.actionSuccess();
  }
  send(data: any) {
    this.invoice=data;
    this.InvoiceAttachmentsService.check(this.invoice.id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.ModalService.open(this.modalSend,'invoiceSend');
        }
    );
  }
  sendSuccess() {
    this.ModalService.close('invoiceSend');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('entity.invoice') + ' '+ this.TranslateService.GetTranslation('entity.sent'));
    this.getData();
  }
  sendClose() {
    this.ModalService.close('invoiceSend');
  }
  handlePayment(id: number) {
    this.id=id;
    this.ModalService.open(this.modalHandlePayment,'stateAction');
  }
  paymentSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.payment-handled'));
    this.actionSuccess();
  }
  reject(data) {
    this.invoice=data;
    this.ModalService.open(this.modalReject,'stateAction');
  }
  rejectSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.rejected'));
    this.actionSuccess();
  }
  stateActionClose() {
    this.ModalService.close('stateAction');
  }
  actionSuccess() {
    this.stateActionClose();
    this.getData();
  }
  copy(id: number) {
    this.id=id;
    this.ModalService.open(this.modalCopy,'stateAction');
  }
  copySuccess(data: any) {
    this.ModalService.close('stateAction');
    this.Router.navigateByUrl('invoicing/'+data.invoice_id.toString())
  }
  settings(id: number) {
    this.id=id;
    this.ModalService.open(this.modalSettings,'settings');
  }
  settingsSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    this.ModalService.close('settings');
    this.getData();
  }
  settingsClose() {
    this.ModalService.close('settings');
  }
  creditNota(data: any) {
    this.invoice=data;
    this.ModalService.open(this.modalCreditNota,'invoiceCreditnota');
  }
  creditNotaSuccess() {
    this.ModalService.close('invoiceCreditnota');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.generated-message'));
  }
  creditNotaClose() {
    this.ModalService.close('invoiceCreditnota');
  }
  remove(data) {
    this.initialCount=null;
    this.invoice=data;
    this.ModalService.open(this.modalRemove,'stateAction');
  }
  removeSuccess() {
    this.stateActionClose();
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
    this.getData(true);
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.invoices$!=undefined) this.invoices$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
