<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'ui.whole'|translate}} {{'invoice.creditnota'|translate|lowercase}} {{'ui.generate'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <jbm-static-label labelClass="md" [labelCaption]="'invoice.number'|translate" [value]="invoice.invoice_number"></jbm-static-label>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <button type="button" class="btn btn-sm btn-primary" (click)="generate()">
         {{'ui.whole'|translate}} {{'invoice.creditnota'|translate|lowercase}} {{'ui.generate'|translate|lowercase}}
      </button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cancel-button (click)="close()"></jbm-cancel-button>
   </div>
</div>
