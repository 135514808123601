<app-header>
  <jbm-view-title [caption]="'entity.customer'|translate" [subCaption]="customer.name"
    [parentLinkCaption]="'menu.customers'|translate" [parentLinkSlug]="'customers'">
  </jbm-view-title>
  <jbm-edit-round-button *ngIf="userRights.AllowUpdate" [className]="'float-start'" (click)="edit()"></jbm-edit-round-button>
  <div class="toolbar btn-toolbar float-end">
    <jbm-activity-log-button subject_type="7" [subject_id]="customer.id"
      subject="{{'entity.customer'|translate}}" [title]="customer.name"></jbm-activity-log-button>
    <jbm-help-button [helpTopicID]="0"></jbm-help-button>
  </div>
</app-header>
<section class="module padding overflow-y bg-white">
  <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs module bg-light left">
    <ng-container ngbNavItem [ngbNavItem]="0">
      <a ngbNavLink>{{'ui.properties'|translate}}</a>
      <ng-template ngbNavContent>
        <customer-details [customer]="customer"></customer-details>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="userRightsQuotations.AllowRead" ngbNavItem [ngbNavItem]="1">
      <a ngbNavLink>{{'menu.quotations'|translate}}</a>
      <ng-template ngbNavContent>
        <quotations-table [customer_id]="customer.id"></quotations-table>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="userRightsProjects.AllowRead" ngbNavItem [ngbNavItem]="2">
      <a ngbNavLink>{{'entity.projects'|translate}}</a>
      <ng-template ngbNavContent>
        <projects-table [customer_id]="customer.id"></projects-table>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="userRightsFinancial.AllowRead" ngbNavItem [ngbNavItem]="3">
      <a ngbNavLink>{{'financial.article-pricelists'|translate}}</a>
      <ng-template ngbNavContent>
        <pricelists [pricelistsType]="0" [customer_id]="customer.id"></pricelists>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="userRightsFinancial.AllowRead" ngbNavItem [ngbNavItem]="4">
      <a ngbNavLink>{{'financial.concrete-pricelists'|translate}}</a>
      <ng-template ngbNavContent>
        <pricelists [pricelistsType]="1" [customer_id]="customer.id"></pricelists>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="userRightsFinancial.AllowRead" ngbNavItem [ngbNavItem]="5">
      <a ngbNavLink>{{'entity.resource'|translate}} {{'financial.pricelists'|translate|lowercase}}</a>
      <ng-template ngbNavContent>
        <resource-pricelists [customer_id]="customer.id"></resource-pricelists>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="mt-3"></div>
</section>
<jbm-toasts></jbm-toasts>
