import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'invoice-view-settings',
  templateUrl: './invoice-view-settings.component.html',
  styleUrls: ['./invoice-view-settings.component.scss']
})
export class InvoiceViewSettingsComponent implements OnInit {
  constructor() { }

  @Input() settings: any;
  @Input() credit_nota: boolean=false;
  @Input() reference_invoice_number: string=null;
  @Input() invoice_usage: number=null;
  @Input() resources_based_invoicing: boolean=false;
  @Input() shifted_vat: boolean=false;

  @Output() onChange=new EventEmitter();
  @Output() onEditReceipts=new EventEmitter();
  @Output() onEditOrders=new EventEmitter();
  @Output() onShowReference=new EventEmitter();

  ngOnInit(): void {}
  toggleDetails() {
    this.settings.details=!this.settings.details;
    this.onChange.emit({settings: this.settings});
  }
  toggleOrders() {
    this.settings.orders=!this.settings.orders;
    this.onChange.emit({settings: this.settings});
  }
  toggleRecipes() {
    this.settings.recipes=!this.settings.recipes;
    this.onChange.emit({settings: this.settings});
  }
  toggleComments() {
    this.settings.comments=!this.settings.comments;
    this.onChange.emit({settings: this.settings});
  }
  toggleResources() {
    this.settings.resources=!this.settings.resources;
    this.onChange.emit({settings: this.settings});
  }
  editReceipts() {
    this.onEditReceipts.emit();
  }
  editOrders() {
    this.onEditOrders.emit();
  }
  showReference() {
    this.onShowReference.emit();
  }
}
