import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {InvoicesService} from "../data/invoices.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Invoice} from "../data/interfaces/invoice";

@Component({
  selector: 'invoice-creditnota',
  templateUrl: './invoice-creditnota.component.html',
  styleUrls: ['./invoice-creditnota.component.scss']
})
export class InvoiceCreditnotaComponent {
  language: string=this.TokenService.getLanguage();
  submitted: boolean=false;

  constructor(
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService,
      private formBuilder: FormBuilder,
      private InvoicesService: InvoicesService,
  ) {}

  @Input() invoice: Invoice;

  @Output() onClose=new EventEmitter();
  @Output() onSuccess=new EventEmitter();

  generate() {
    this.InvoicesService.generateCreditNota(this.invoice.id).subscribe(
        ()=>this.onSuccess.emit(), (error)=>console.error(error))
  }
  close() {
    this.onClose.emit();
  }
}
