<app-header>
   <jbm-view-title
     [caption]="('menu.production'|translate)+('production.receipts'|translate|lowercase)"
     [itemcount]="queryHttpHelper.paginationProps.itemCount"
     iconClassname="industry"
   >
   </jbm-view-title>
   <div class="toolbar float-end">
      <jbm-spinner [loading]="loading"></jbm-spinner>
      <div *ngIf="unsynchronised" class="toolbar-spacer-1 float-end"></div>
      <button *ngIf="projectRights.AllowUpdate && unsynchronised" class="btn btn-secondary" (click)="synchronise()">
         {{'ui.synchronise'|translate}}... <span class="badge bg-light text-dark">{{unsynchronised}}</span>
      </button>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
        [pageSize]="queryHttpHelper.paginationProps.pageSize"
        [page]="queryHttpHelper.paginationProps.page" (pageClick)="pageChange($event)">
      </jbm-pagination>
      <jbm-searchbox [visible]="searchboxVisible && !filtersVisible" [search]="queryHttpHelper.getSearchValue()"
        [minlength]="3" (searchChange)="searchChange($event)" [id]="'receipt-search'">
      </jbm-searchbox>
      <div class="toolbar-spacer-1"></div>
      <div class="btn-group">
         <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
      </div>
      <div class="toolbar-spacer-2 float-end"></div>
      <jbm-help-button [helpTopicID]="7"></jbm-help-button>
   </div>
</app-header>
<section class="module overflow-y production-receipts">
   <div class="bg-blue-extra-light pt-1 pb-2">
      <div>
         <jbm-date-range-input [setToday]="true" [class]="'float-start ml-4 pt-1 pb-1'" (onSelectRange)="setDateRange($event)" (onUndo)="undoDateRange()"></jbm-date-range-input>
         <div class="toolbar ml-4 float-start">
            <label *ngIf="plantOptions.length>1">{{'company.plant'|translate}}</label>
            <select *ngIf="plantOptions.length>1" (change)="changePlant($event)" class="form-control form-control-sm form-select">
               <option *ngFor="let plant of plantOptions; let i=index" [selected]="plantOptions[i].key==plants_id.toString()" value="{{plant.key}}">{{plant.value}}</option>
            </select>
         </div>
      </div>
      <div class="receipt-totals mt-2 ml-4">
         <div *ngFor="let total of plantTotals" class="float-start mr-3">
            <div class="float-start mr-1">
               {{total.code}}
            </div>
            <div class="float-start total">
               <span class="badge bg-secondary">{{total.amountTotal}} {{'quantity.m3'|translate}}</span>
            </div>
         </div>
      </div>
   </div>
   <jbm-table #table
     [absolute]="true"
     [classname]="'padding'"
     [data]="receipts"
     [filterable]="true"
     [showFilters]="filtersVisible"
     [searchMinLength]="3"
     [sortable]="true"
     [sortColumn]="queryHttpHelper.sortProps.name"
     [sortDirection]="queryHttpHelper.sortProps.direction"
     [hoverable]="true"
     [rowSelectable]="true"
     [columnDefs]="columnDefs"
     [actionDefs]="actionDefs"
     [template]="details"
     (rowCountChange)="rowCountChange($event)"
     (sortChange)="sortChange($event)"
     (filterChange)="filterChange($event)"
     (rowSelect)="rowSelect($event)">
   </jbm-table>
</section>
<jbm-toasts></jbm-toasts>

<jbm-modal [id]="'recipeDetails'" [className]="'recipe-details'"></jbm-modal>
<ng-template #modalRecipeDetails let-modal>
   <recipe-details [order_id]="order_id" [recipe_id]="recipe_id"  (onClose)="closeRecipe()"></recipe-details>
</ng-template>

<ng-template #orderTemplate let-data="data">
   <button *ngIf="projectRights.AllowUpdate" class="btn btn-sm btn-link text-left pl-0"
     [ngbTooltip]="'ui.click-item-open'|translate"
     [placement]="'right'"
     (click)="redirectToOrder(data.order_id);$event.stopPropagation();">
      {{data.order_id}}
   </button>
   <span *ngIf="!projectRights.AllowUpdate">{{data.order_id}}</span>
</ng-template>

<ng-template #truckTemplate let-data="data">
   {{data.truck_number}} <span *ngIf="data.driver">- {{data.driver}}</span>
</ng-template>

<ng-template #recipeTemplate let-data="data">
   <button *ngIf="recipeRights.AllowUpdate && data.recipe_id" class="btn btn-sm btn-link text-left pl-0"
     [ngbTooltip]="('entity.recipe'|translate)+' '+('ui.details'|translate|lowercase)"
     (click)="recipeDetails(data.order_id, data.recipe_id);$event.stopPropagation();">
      {{data.recipe_id}} - {{data.recipe}}
   </button>
</ng-template>

<ng-template #details let-data="data">
   <div class="pl-3 pr-3 pb-3 bg-light-darker">
      <production-details [receipt_id]="data.id"></production-details>
   </div>
</ng-template>

<ng-template #expandTemplate let-data="data">
   <jbm-confirm-delete-dropdown *ngIf="!data.loading_time" [subject]="'entity.delete'|translate" [placement]="'left'"
     (onConfirm)="confirmDelete(data.id)">
   </jbm-confirm-delete-dropdown>
   <button *ngIf="data.loading_time && data.has_production_lines" class="btn btn-light btn-sm" (click)="rowSelect(data);$event.stopPropagation()">
     {{'ui.details'|translate}}
   </button>
</ng-template>

<ng-template #dropTemplate let-data="data">
   <span *ngIf="data.dropped" class="badge bg-danger">{{'ui.dropped'|translate}}</span>
   <button *ngIf="dropReceiptRights.AllowCreate && !data.dropped" class="btn btn-danger btn-sm" (click)="drop(data)" [ngbTooltip]="'production.drop-receipt'|translate">
      <i class="fa fa-undo"></i>...
   </button>
</ng-template>

<jbm-modal [id]="'dropReceipt'" [className]="'drop-receipt'"></jbm-modal>
<ng-template #modalDrop let-data="data">
   <drop-receipt [id]="id" [receipt_id]="receipt_id" (onClose)="closeDrop()" (onSuccess)="successDrop()"></drop-receipt>
</ng-template>
