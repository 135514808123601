import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrdersService} from "../../projects/data/orders.service";
import {RecipesService} from "../data/recipes.service";
import {RecipeArticlesService} from "../data/recipe_articles.service";
import {RecipeResourcesService} from "../data/recipe_resources.service";
import {TokenService} from "../../../services/auth/token.service";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'recipe-details',
  templateUrl: './recipe-details.component.html',
  styleUrls: ['./recipe-details.component.scss']
})
export class RecipeDetailsComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  order: any={};
  recipe: any={};
  consistency: string='';
  strength: string='';
  environment: string='';
  articles: any[]=[];
  resources: any[]=[];

  constructor(
      private OrdersService: OrdersService,
      private RecipesService:RecipesService,
      private RecipeArticlesService: RecipeArticlesService,
      private RecipeResourcesService: RecipeResourcesService,
      private TokenService: TokenService
  ) { }

  @Input() order_id: number;
  @Input() recipe_id: number;

  @Output() onClose=new EventEmitter();

  ngOnInit(): void {
    let params=new HttpParams().append('environment','1');
    this.OrdersService.getOne(this.order_id, this.language,params).subscribe(
        (data)=>this.order=data.data,
        (error)=>console.error(error),
        ()=> {
            this.environment=this.order.environment ? this.order.environment.description : '';

            let params=new HttpParams().append('concrete-specifications','1');
            this.RecipesService.getOne(this.recipe_id, this.language, params).subscribe(
                (data) => this.recipe=data.data,
                (error) => console.error(error),
                () => {
                    this.consistency=this.recipe.consistency.description;
                    this.strength=this.recipe.strength ? this.recipe.strength.description : '';

                    this.RecipeArticlesService.getArticles(this.recipe_id, this.language).subscribe(
                        (data) => this.articles=data.data,
                        (error) => console.error(error),
                        () => {
                            for(let article of this.articles)
                                article.amount=article.amount.toString().replace('.', ',');

                            this.RecipeResourcesService.get(this.recipe_id).subscribe(
                                (data) => this.resources=data.data,
                                (error) => console.error(error),
                                () => {
                                    for(let resource of this.resources)
                                        resource.amount=resource.amount.toString().replace('.', ',');
                                }
                            )
                        }
                    )
                }
            )
        }
    )
  }
  close() {
    this.onClose.emit();
  }
}
