import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "../../../../services/multilingual/translate.service";

@Component({
  selector: 'jbm-link-button',
  templateUrl: './JBMLinkButton.component.html',
  styleUrls: ['./JBMLinkButton.component.scss'],
  host: {'style': 'padding: 0' }
})
export class JBMLinkButtonComponent implements OnInit {

  constructor(private TranslateService: TranslateService) { }

  @Input() caption: string;
  @Input() disabled: boolean=false;
  @Input() tooltip: any;
  @Input() class: string;
  @Input() style: string;
  @Input() iconClass: string;
  @Input() showIcon: boolean=true;

  @Output() onClick = new EventEmitter();

  ngOnInit(): void {
    if(this.tooltip==undefined)
      this.tooltip=this.TranslateService.GetTranslation('ui.details') + ' ' + this.TranslateService.GetTranslation('ui.show-verb');
    if(this.iconClass==undefined)
      this.iconClass='fa fa-info';
  }
  click() {
    this.onClick.emit();
  }
}
