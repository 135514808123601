<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.recipe'|translate}} {{'ui.details'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <div class="jbm-properties-list fields">
      <div class="item">
         <label class="label">{{'entity.code'|translate}}:</label>
         <label class="value">{{recipe.code}}</label>
      </div>
      <div class="item">
         <label class="label">{{'entity.description'|translate}}:</label>
         <label class="value">{{recipe.description}}</label>
      </div>
      <div *ngIf="recipe.internal_description" class="item">
         <label class="label">{{'recipe.jonker-number'|translate}}:</label>
         <label class="value">{{recipe.internal_description}}</label>
      </div>
   </div>
   <hr class="mt-2 mb-2" />
   {{'concrete.consistency-short'|translate}}: <span class="fw-bold mr-4">{{consistency}}</span>
   <span *ngIf="strength">{{'concrete.strength-short'|translate}}: <span class="fw-bold mr-4">{{strength}}</span></span>
   <span *ngIf="environment">{{'concrete.environment-short'|translate}}: <span class="fw-bold mr-4">{{environment}}</span></span>

   <table *ngIf="resources.length" class="table table-xs mt-3">
      <thead>
         <th>{{'entity.resource'|translate}}</th>
         <th class="align-right">{{'order.amount'|translate}}</th>
      </thead>
      <tbody>
         <tr *ngFor="let resource of resources">
            <td>{{resource.description}}</td>
            <td class="align-right">{{resource.amount}}</td>
         </tr>
      </tbody>
   </table>

   <table *ngIf="articles.length" class="table table-xs mt-3">
      <thead>
      <th>{{'financial.article'|translate}}</th>
      <th class="align-right">{{'order.amount'|translate}}</th>
      </thead>
      <tbody>
      <tr *ngFor="let article of articles">
         <td>{{article.article.description}}</td>
         <td class="align-right">{{article.amount}}</td>
      </tr>
      </tbody>
   </table>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <jbm-close-button (click)="close()"></jbm-close-button>
   </div>
</div>

