<app-header>
   <jbm-view-title [caption]="('menu.production'|translate) + ' ' + ('reports.report'|translate|lowercase)"
     [iconClassname]="iconClassname"
     [parentLinkCaption]="'menu.reports'|translate"
     [parentLinkSlug]="'reports'">
   </jbm-view-title>
   <div class="toolbar ml-4 float-start">
      <label>{{'company.plant'|translate}}</label>
      <select (change)="selectPlant($event)" class="form-control form-control-sm form-select">
         <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
      </select>
   </div>
</app-header>
<section class="module report">
   <div class="pl-4 pr-4 pt-2 report-settings bg-blue-extra-light clearfix">
      <form class="row">
         <div class="col-5">
            <customer-selector [hidden]="project_id" [template]="customer" [id]="'customer'" [class]="'lg'" [labelClass]="'xxs'" labelCaption="{{'entity.customer'|translate}}"
               [labelStacked]="false" [customer_id]="customer_id" (customerSelected)="selectCustomer($event)">
            </customer-selector>
            <ng-template #customer let-customer="customer">
               <div *ngIf="customer">
                  {{customer.name}}
               </div>
            </ng-template>
            <project-selector [template]="project" [id]="'project'" [class]="'lg'" [labelClass]="'xxs'" labelCaption="{{'entity.project'|translate}}"
              [labelStacked]="false" [project_id]="project_id" (projectSelected)="selectProject($event)">
            </project-selector>
            <ng-template #project let-project="project">
               <div *ngIf="project">
                  {{project.name}}
               </div>
            </ng-template>
         </div>
         <div class="col-6">
            <div class="clearfix">
               <jbm-date-range-input [hidden]="project_id" [setToday]="true" [class]="'ml-3'" (onSelectRange)="setDateRange($event)" (onUndo)="undoDateRange()"></jbm-date-range-input>
            </div>
            <div class="mt-2 ml-2 clearfix">
               <jbm-check [hidden]="customer_id || project_id" [formControl]="settings.controls['per_customer']" [id]="'per_customer'" [labelCaption]="('reports.per'|translate)+' '+('entity.customer'|translate|lowercase)" class="float-start" (change)="renderReport()"></jbm-check>
               <jbm-check [hidden]="project_id" [formControl]="settings.controls['per_day']" [id]="'per_day'" [labelCaption]="('reports.per'|translate)+' '+('ui.calendar-day'|translate|lowercase)" class="float-start" (change)="renderReport()"></jbm-check>
               <jbm-check [hidden]="project_id" [formControl]="settings.controls['per_recipe']" [id]="'per_recipe'" [labelCaption]="('reports.per'|translate)+' '+('entity.recipe'|translate|lowercase)" class="float-start" (change)="renderReport()"></jbm-check>
               <jbm-check [hidden]="project_id || (!settings.controls['per_customer'].value && !settings.controls['per_recipe'].value && !settings.controls['per_day'].value)" [formControl]="settings.controls['include_totals']" [id]="'include_totals'" [labelCaption]="('ui.inclusive'|translate)+' '+('ui.totals'|translate|lowercase)" class="float-start" (change)="renderReport()"></jbm-check>
            </div>
            <div class="mt-1 mb-2 pl-3 clearfix">
               <label class="col-form-label float-start mr-2">{{'ui.show'|translate}}</label>
               <select class="form-control form-select width-15 float-start" id="view" (change)="changeView($event)">
                  <option value="0">{{'ui.actually'|translate}} {{'production.produced'|translate|lowercase}}</option>
                  <option value="1">{{'entity.customer'|translate}} {{'production.produced'|translate|lowercase}}</option>
               </select>
            </div>
         </div>
      </form>
   </div>
   <div [hidden]="empty" class="viewer pl-4 pr-4 pb-4 clearfix">
      <div class="content-secondary pdf-viewer">
         <object id="pdfObject" type="application/pdf">
            <iframe id='pdfIFrame'></iframe>
         </object>
      </div>
   </div>
   <jbm-empty-view [emptyView]="empty"></jbm-empty-view>
</section>
