<div class="form-group row {{class}}" [ngClass]="{'stacked': labelStacked, 'readonly': !editable}">
    <label *ngIf="labelCaption" for="{{id}}" class="{{labelClass}}" [ngClass]="{'col-form-label': !labelStacked }">
        {{labelCaption}}
        <span *ngIf="!editable">:</span>
        <span *ngIf="editable && required" class="asterix">*</span>
    </label>
    <div class="input-container controls group float {{inputClass}}">
        <div class="input-group">
            <div *ngIf="prepend" class="input-group-prepend">
                <span class="input-group-text">{{prepend}}</span>
            </div>
            <label *ngIf="!editable" class="col-form-label" [ngClass]="{'col-form-label-sm': sm}">{{getDataValue()}}</label>
            <input *ngIf="editable" float-only-input id="{{id}}" [type]="type" [formControl]="ngControl.control"
              [attr.maxlength]="maxlength>0 ? maxlength : null" [attr.tabindex]="tabIndex!==null ? tabIndex : null"
              (blur)="blur($event)" class="form-control align-right" [ngClass]="{'is-invalid': editable && ngControl.invalid &&
               (ngControl.touched || submitted), 'form-control-sm': sm, 'negative': negativeAllowed }"
              autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false">
            <div *ngIf="append" class="input-group-append">
                <span class="input-group-text">{{append}}</span>
            </div>
        </div>
        <ng-content></ng-content>
    </div>
</div>

