<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.recipe'|translate}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <div class="row">
      <div class="col-7">
         <div class="jbm-properties-list recipe">
            <div class="item">
               <label class="label">{{'entity.recipe'|translate}}:</label>
               <label class="value">{{recipe.code}}</label>
            </div>
            <div *ngIf="!environment" class="item">
               <label class="label"></label>
               <label class="value">({{'recipe.delivery-composition'|translate}})</label>
            </div>
            <div *ngIf="delivery_date" class="item">
               <label class="label">{{'order.production-date'|translate}}:</label>
               <label class="value">{{delivery_date}}</label>
            </div>
         </div>
      </div>
      <div class="col-5">
         <h6>{{'financial.concrete-specs'|translate}}</h6>
         <div class="jbm-properties-list concrete">
            <div *ngIf="recipe.strength" class="item">
               <label class="label">{{'concrete.strength'|translate}}:</label>
               <label class="value">{{recipe.strength.description}}</label>
            </div>
            <div *ngIf="environment" class="item">
               <label class="label">{{'concrete.environment'|translate}}:</label>
               <label class="value">{{environment}}</label>
            </div>
            <div class="item">
               <label class="label">{{'concrete.consistency'|translate}}:</label>
               <label class="value">{{recipe.consistency.description}}</label>
            </div>
         </div>
      </div>
   </div>

   <h6 *ngIf="recipe.internal_description" class="mb-1" style="margin-top: -1rem">{{'entity.notes'|translate}}</h6>
   <jbm-comments-box [comments]="recipe.internal_description" height="2.75" [hideIfEmpty]="true"></jbm-comments-box>

   <h6 class="mt-3">{{'entity.resources'|translate}}</h6>
   <table [hidden]="!recipe.resources.length" class="table table-xs recipe-resources">
      <thead>
      <tr>
         <th class="description">{{'entity.resource'|translate}}</th>
         <th class="group">{{'entity.type'|translate}}</th>
         <th class="amount align-right">{{'order.amount'|translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let resource of recipe.resources">
         <td>{{resource.description}}</td>
         <td>{{resource.type.description}}</td>
         <td class="align-right">{{resource.amount}} {{'quantity.kilo-short'|translate}}</td>
      </tr>
      </tbody>
   </table>
   <jbm-empty-view [emptyView]="!recipe.resources.length" [className]="'xs'"></jbm-empty-view>
   <br />
   <h6>{{'entity.recipe'|translate}} {{'financial.articles'|translate|lowercase}}</h6>
   <table [hidden]="!recipe.articles.length" class="table table-xs recipe-articles">
      <thead>
      <tr>
         <th class="description">{{'financial.article'|translate}}</th>
         <th class="group">{{'ui.group'|translate}}</th>
         <th class="amount align-right">{{'order.amount'|translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let article of recipe.articles">
         <td>{{article.article.name}}</td>
         <td>{{article.article.group}}</td>
         <td class="align-right">{{article.amount}}</td>
      </tr>
      </tbody>
   </table>
   <jbm-empty-view [emptyView]="!recipe.articles.length" [className]="'xs'"></jbm-empty-view>
</div>
<div class="jbm-modal-footer">
   <jbm-close-button class="float-end" (click)="close()"></jbm-close-button>
</div>
