import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {PriceService} from "../../../../services/price/price.service";
import {OrdersService} from "../../data/orders.service";
import {OrderPresenter} from "../../presenters/order.presenter";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {HttpParams} from "@angular/common/http";
import {GroupRights} from "../../../users/data/interfaces/grouprights";
import {DatetimeService} from "../../../../services/datetime/datetime.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";

@Component({
  selector: 'orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  items: any[]=[];
  itemsLoaded: boolean=false
  id: number;
  financialRights: GroupRights;
  loadingTime: number;
  endTime: string;
  recipe_id: number;
  environment: string;

  items$: Subscription;
  count$: Subscription;
  timer: any;

  @Input() language: string;
  @Input() projects_id: number;
  @Input() plants_id: number;
  @Input() customers_id: number;
  @Input() travelTime: number;
  @Input() editable: boolean;

  @ViewChild('modalAmount') modalAmount: ElementRef;
  @ViewChild('modalCopy') modalCopy: ElementRef;
  @ViewChild('modalRecipe') modalRecipe: ElementRef;

  constructor(
      private Router: Router,
      private OrdersService: OrdersService,
      private OrderPresenter: OrderPresenter,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private PriceService: PriceService,
      private DatetimeService: DatetimeService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService
  ) {}

  ngOnInit(): void {
    this.financialRights=this.TokenService.getRightsByName('financial');

    this.endTime='';

    // Get truck loading time
    this.OrdersService.getLoadingTime().subscribe(
        (data)=>this.loadingTime=data.data,
        (error)=>console.error(error),
        ()=>this.getData()
    )
  }
  getData() {
    clearTimeout(this.timer);

    let getParams=new HttpParams()
      .append('concretetotalprice','1')
      .append('count_articles','1');
    this.items$=this.OrdersService.get(this.projects_id, this.language, getParams).subscribe(
      (data: any)=> {
        this.items=data.data
        this.itemsLoaded=true
      },
      (error)=>console.error(error),
      ()=> {
        this.formatData();
        // Refresh view every minute (Periodically poll or server refresh)
        this.timer=setTimeout(()=> this.getData(),60000)
      });
  }
  formatData() {
    for(let i=0; i<this.items.length; i++) {

      // Is order in production?
      this.items[i].inProduction=false;
      if(this.items[i].production_date) {
        let today=this.DatetimeService.dateYMDToDMY(this.DatetimeService.getDateToYMD(new Date()));
        this.items[i].inProduction=(today===this.items[i].production_date);
      }

      // Calculate production load time and end time
      if(this.travelTime) {
        this.items[i].loadtime = '';
        this.items[i].endtime = '';

        let unloading_flow = this.items[i].unloading_flow;
        if ( !unloading_flow)
          unloading_flow = this.items[i].unloading_method.speed;

        if (this.items[i].production_date !== null && this.items[i].starttime !== null && this.items[i].loadtime !== null) {
          this.items[i].loadtime = this.OrdersService.calcLoadTime(this.items[i].starttime, this.travelTime, this.loadingTime);
          this.items[i].endtime = this.OrdersService.calcEndTime(this.items[i].starttime, this.items[i].amount, unloading_flow);
        }
      }
      // Completed progress
      this.items[i].completed=100;
      if((this.items[i].delivered<this.items[i].amount))
        this.items[i].completed=Math.round((this.items[i].delivered / this.items[i].amount) * 100);

      this.items[i]=this.OrderPresenter.format(this.items[i]);
      this.items[i].collapsed=true;
    }
  }
  showRecipe(recipe_id: number, environment: string) {
    this.recipe_id=recipe_id;
    this.environment=environment;
    this.ModalService.open(this.modalRecipe,'recipe');
  }
  closeRecipe() {
    this.ModalService.close('recipe');
  }

  /* Amount */

  editAmount(order_id: number) {
    this.id=order_id;
    this.ModalService.open(this.modalAmount,'amount');
  }
  onSaveAmountDialog() {
    this.ModalService.close('amount');
    this.getData();
  }
  onCancelAmountDialog() {
    this.ModalService.close('amount');
  }

  /* Copy */

  copy(order: any) {
    this.id=order.id;
    this.ModalService.open(this.modalCopy,'copy');
  }
  onSaveCopy() {
    this.ModalService.close('copy');
    this.getData();
  }
  onCancelCopy() {
    this.ModalService.close('copy');
  }
  // Item actions
  addItem() {
    this.Router.navigateByUrl('projects/'+this.projects_id+'/orders/create');
  }
  rowSelect(row) {
    this.Router.navigateByUrl('project/'+row.id);
  }
  edit(order_id: number) {
    this.Router.navigateByUrl('projects/'+this.projects_id+'/orders/edit/'+order_id.toString());
  }
  deleteItem(id) {
    this.OrdersService.delete(id).subscribe(
        ()=>{},(error)=>console.error(error),
        ()=> {
          this.showDeletedToast();
          this.getData();
        })
  }
  showDeletedToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();

    clearTimeout(this.timer);
  }
}
