import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class KeyboardShortcutsService {

  constructor(private Router: Router) {}

  handleShortCut(event: KeyboardEvent) {
    if(!event.ctrlKey) return;
    if(this.isEventFromInput(event)) return;

    switch(event.code) {
      case 'KeyD':
        this.Router.navigateByUrl('dashboard');
        event.preventDefault();
        break;
      case 'KeyK':
        this.Router.navigateByUrl('customers');
        event.preventDefault();
        break;
      case 'KeyQ':
        this.Router.navigateByUrl('quotations');
        event.preventDefault();
        break;
      case 'KeyP':
        this.Router.navigateByUrl('projects');
        event.preventDefault();
        break;
      case 'KeyL':
        this.Router.navigateByUrl('planning');
        event.preventDefault();
        break;
      case 'KeyI':
        this.Router.navigateByUrl('invoicing');
        event.preventDefault();
        break;
      case 'KeyS':
        this.Router.navigateByUrl('settings');
        event.preventDefault();
        break;
      case 'KeyH':
        // Open the help centre
        (<HTMLButtonElement>document.getElementById('app-help')).click();
        event.preventDefault();
    }
  }
  isEventFromInput( event: KeyboardEvent ) : boolean {
    if(event.target instanceof Node) {
      if(event.target.nodeName==='INPUT') return true;
      if(event.target.nodeName==='SELECT') return true;
      if(event.target.nodeName==='TEXTAREA') return true;
      return false;
    }
    return false;
  }
}
